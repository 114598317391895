



























































































































































































































































































































































import {
    apideleteStaffIndex,
    apieditStaffIndex,
    apiSaveStaffIndex,
    apiStaffListsIndex,
    apideleteStaffallIndex,
    apichangeStaffIndex,
    apienableStaffIndex,
    apiimportIndex,
} from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce, copyObject } from "@/utils/util";
import SettlementEditor from "@/views/finance/editor/settlementEditor.vue";
import LsUpload from "@/components/ls-upload.vue";
import configs from "@/config";
import { validator } from "@/utils/util";

@Component({
    components: {
        SettlementEditor,
        lsPagination,
        ExportData,
        LsDialog,
        LsUpload,
    },
})
export default class StaffList extends Vue {
    apiStaffListsIndex = apiStaffListsIndex;
    $refs!: { add_form: any };

    form: any = {
        name: "",
        phone: "",
        email: "",
        birthday: "",
        job_no: "",
        status: "",
    };
    visible: any = false;
    mode: any = "add";
    tableint: any = "";
    pager: RequestPaging = new RequestPaging();

    searchObj: any = {
        job_no: "",
        // customer_name: '',
        name: "",
        phone: "",
        birthday: "",
        status: "",
        platform_business_id: this.$route.query.id,
    };

    handleSelectionChange(e: any) {
        // this.paneTable = value;
        this.ids = e.map((v: any) => v.id);
    }
    // 表单校验
    rules = {
        phone: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            {
                validator: (required: object, value: number, callback: any) =>
                    validator("phone", value, callback),
                message: "请输入正确的手机号码",
                trigger: "blur",
            },
        ],
        name: [{ required: true, message: "请输入员工名称", trigger: "blur" }],
        email: [{ required: true, message: "请输入员工邮箱", trigger: "blur" }],
        job_no: [
            { required: true, message: "请输入员工邮箱", trigger: "blur" },
        ],
        birthday: [
            { required: true, message: "请输入员工生日", trigger: "blur" },
        ],
    };

    //编辑
    dialogVisible: boolean = false;

    //批量添加员工
    addallstaff: boolean = false;
    //id
    id: number = 0;

    ids: any[] = [];

    //重置表单
    resetForm() {
        this.form = {
            name: "",
            phone: "",
            email: "",
            birthday: "",
            job_no: "",
        };
    }
    //打开添加弹窗
    async openVisble(type: any, row: any) {
        this.mode = type;
        this.visible = true;
        this.form = JSON.parse(JSON.stringify(row));
    }
    //关闭弹窗
    handClose() {
        this.visible = false;
        this.resetForm();
    }
    //新增员工
    async staffSave() {
        const data = {
            name: this.form.name,
            phone: this.form.phone,
            email: this.form.email,
            birthday: this.form.birthday,
            job_no: this.form.job_no,
            platform_business_id: this.$route.query.id,
            id: this.form.id,
        };
        this.$refs["add_form"].validate(async (valid) => {
            if (valid) {
                this.mode === "add"
                    ? await apiSaveStaffIndex(data)
                    : await apieditStaffIndex(data);
                this.visible = false;
                this.getStaffList();
            } 
        });
    }

    //列表
    getStaffList(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiStaffListsIndex,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getStaffList();
    }

    //打开批量添加
    async addallstaffOpen() {
        this.addallstaff = true;
    }

    //删除员工
    async deletestaff(id: any) {
        apideleteStaffIndex({ id }).then(() => {
            this.getStaffList();
        });
    }
    //批量删除
    async deleteallstaff() {
        apideleteStaffallIndex({ id: this.ids }).then(() => {
            this.getStaffList();
        });
    }

    //冻结员工
    async changeStaffIndex(id: any) {
        apichangeStaffIndex({ id }).then(() => {
            this.getStaffList();
        });
    }

    //多选冻结员工
    async morechangeStaffIndex() {
        apichangeStaffIndex({ id: this.ids }).then(() => {
            this.getStaffList();
        });
    }
    //启用员工
    async enableStaffIndex(id: any) {
        apienableStaffIndex({ id }).then(() => {
            this.getStaffList();
        });
    }

    //多选启用员工
    async moreenableStaffIndex() {
        apienableStaffIndex({ id: this.ids }).then(() => {
            this.getStaffList();
        });
    }

    async imports() {
        const data = {
            id: this.$route.query.id,
        };

        const res: any = await apiimportIndex({ ...data }).finally(() => {});
        this.getStaffList();
    }
    //下载模板
    async downLoad() {
        //   const data = {
        //   id: this.form.id,
        // };
        // const res: any = await apidownLoadIndex({...data }).finally(() => {
        //   this.addallstaff = false;
        // });
        //
        // this.getStaffList();
        const url = configs.baseURL + "/platformapi/staff.Staff/export";
        window.open(url, "_blank");
    }

    // 关闭
    uploadChange(e: any) {
        if (e == "close") {
            this.getStaffList();
        }
        this.getStaffList();
    }

    /** S ods **/
    created() {
        this.searchObj.customer_name = this.$route.query.company_name;
        this.getStaffList();
    }
}
