




























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { RequestPaging, copyObject, timeFormat } from "@/utils/util";
import { orderType } from "@/utils/type";
import { apiSettlementSubpageLists } from "@/api/order/order";
import LsPagination from "@/components/ls-pagination.vue";
import DatePicker from "@/components/date-picker.vue";

@Component({
    components: {
        LsPagination,
        DatePicker,
    },
    watch: {
        settlementVisible(value) {
            let that = <SettlementEditor>this;
            if (value) {
                that.rows = copyObject(that.info);
                that.getList();
            }
        },
    },
})
export default class SettlementEditor extends Vue {
    settlementVisible = false;
    type = 1;
    timeFormat = timeFormat;
    info: any = {};
    rows: any = {};

    searchObj: any = {
        order_sn: "",
        after_sale_sn: "",
        start_time: "",
        end_time: "",
    };
    orderType = orderType;

    pager: RequestPaging = new RequestPaging();

    closeSettlementVisible() {
        this.info = {};
        this.type = 1;
        this.settlementVisible = false;
        this.$emit("close");
    }
    getList() {
        this.pager
            .request({
                callback: apiSettlementSubpageLists,
                params: {
                    set_id: this.rows.id,
                    ...this.searchObj,
                },
            })
            .then((res: any) => {});
    }
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getList();
    }
}
